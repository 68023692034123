$spruce-green: #00655d;
$terra-cotta: #ac5c35;
$deep-forest: #004b45;
$bronze: #e29a6d;

// scss-docs-start theme-color-variables
$primary: $spruce-green;
$secondary: $terra-cotta;
$tertiary: $deep-forest;
$quaternary: $bronze;
$white: #ffffff;
$black: #000000;
$gray: #cccccc;
$disabled: #6c757d;
$marker-orange: $bronze;
$input-btn-padding-x: 2rem;

$gray-dark: darken($gray, 20%);

$primary-light: lighten($primary, 20%);
$primary-light-hover: lighten($primary, 35%);
$primary-dark: darken($primary, 5%);
$primary-dark-alt: $deep-forest;
$primary-dark-hover: darken($primary, 25%);

$secondary-light: lighten($primary, 20%);
$secondary-light-hover: lighten($primary, 25%);
$secondary-dark: darken($primary, 30%);
$secondary-dark-hover: darken($primary, 35%);
$danger: rgb(220, 53, 69);

// Font Variables
$garamond: "EB Garamond", serif;

// Footer Variables
$footer-bg: $spruce-green;

:export {
  primary: $primary;
  primaryLight: $primary-light;
  primaryLightHover: $primary-light-hover;
  primaryDark: $primary-dark;
  primaryDarkAlt: $primary-dark-alt;
  primaryDarkHover: $primary-dark-hover;
  secondary: $secondary;
  tertiary: $tertiary;
  quaternary: $quaternary;
  markerOrange: $marker-orange;
  white: $white;
  black: $black;
  gray: $gray;
  grayDark: $gray-dark;
  offBlack: rgb(65, 66, 68);
  danger: $danger;
}

// Modal Drawer
$drawer-width: 580px;
$wide-drawer-width: 900px;
$full-drawer-width: 100%;
$modal-header-color: #f5f5f5;

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xg: 1100px,
  xl: 1200px,
  xxl: 1400px,
);

$form-select-bg-position: right 0.5rem center;

// Accordion
$accordion-body-padding-y: 0 1rem;
$accordion-border-color: var(--bs-primary);
$accordion-button-bg: transparent;
$accordion-button-color: var(--bs-primary);
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: transparent;
$accordion-button-color: var(--bs-primary);
